import {
  call,
  put,
  takeLatest,
  debounce,
  cancel,
  fork,
  take,
  cancelled
} from "redux-saga/effects";
import { GetDls, GetDlColumnValues } from "../../api/ninjaDlApi";
import axios from "../../api/axios";

import {
  FETCH_DLS,
  FETCH_DLS_SUCCEEDED,
  FETCH_DLS_FAILED,
  FETCHED_COLUMN_VALUES,
  FETCH_COLUMN_VALUES,
  CANCEL_FETCH_COLUMN_VALUES
} from "../types/ninjaDl";

function* getAllDls() {
  try {
    const response = yield call(async () => {
      return await GetDls();
    });

    yield put({
      type: FETCH_DLS_SUCCEEDED,
      dls: response.data
    });
  } catch (e) {
    yield put({ type: FETCH_DLS_FAILED, message: e });
  }
}
function* fetchColumnValuesTask(action) {
  let ajaxRequest = axios.CancelToken.source();
  try {
    const response = yield call(async () => {
      return await GetDlColumnValues(
        action.dlId,
        action.column,
        action.query,
        ajaxRequest.token
      );
    });
    yield put({
      type: FETCHED_COLUMN_VALUES,
      values: response.data
    });
  } catch (error) {
    yield put({
      type: FETCHED_COLUMN_VALUES,
      values: []
    });
  } finally {
    if (yield cancelled()) {
      ajaxRequest.cancel();
    }
  }
}

function* fetchColumnValues(action) {
  yield put({
    type: CANCEL_FETCH_COLUMN_VALUES
  });
  const task = yield fork(fetchColumnValuesTask, action);
  yield take(CANCEL_FETCH_COLUMN_VALUES);
  yield cancel(task);
}

function* dl() {
  yield takeLatest(FETCH_DLS, getAllDls);
  yield debounce(500, FETCH_COLUMN_VALUES, fetchColumnValues);
}

export default dl;
