import { takeLatest, call, put } from "redux-saga/effects";
import { GetFontAwesomeIcons } from "../../api/iconApi";
import { GetIcons } from "../../api/imageApi";
import {
  FETCH_ICONS_FAILED,
  FETCH_FONTAWESOME_ICONS_FAILED,
  FETCH_FONTAWESOME_ICONS,
  FETCH_ICONS_SUCCESS,
  FETCH_FONTAWESOME_ICONS_SUCCESS,
  FETCH_ICONS
} from "../types/icons";

function* getFontAwesomeIcons(action) {
  try {
    const icons = yield call(async () => {
      return await GetFontAwesomeIcons(action.users);
    });
    yield put({ type: FETCH_FONTAWESOME_ICONS_SUCCESS, icons: icons.data });
  } catch (e) {
    yield put({ type: FETCH_FONTAWESOME_ICONS_FAILED, message: e });
  }
}

function* getIcons() {
  try {
    const icons = yield call(async () => {
      return await GetIcons();
    });
    yield put({ type: FETCH_ICONS_SUCCESS, icons: icons.data });
  } catch (e) {
    yield put({ type: FETCH_ICONS_FAILED, message: e });
  }
}

function* icon() {
  yield takeLatest(FETCH_FONTAWESOME_ICONS, getFontAwesomeIcons);
  yield takeLatest(FETCH_ICONS, getIcons);
}

export default icon;
