import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  CreateMatView,
  GetKpiRealTimeData,
  GetProductRealTimeData,
  GetProductUsers,
  UpdateDataSourceProcedure,
  UpdateDlProcedure
} from "../../api/realTimeApi";
import { ADD_USER_SUCCEEDED } from "../types/dl";
import {
  ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
  DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED
} from "../types/managedUser";
import {
  FETCH_REAL_TIME_KPI_DATA,
  FETCH_REAL_TIME_KPI_DATA_SUCCESS,
  FETCH_REAL_TIME_KPI_DATA_FAILED,
  FETCH_PRODUCT_USERS_SUCCESS,
  FETCH_PRODUCT_USERS_FAILED,
  FETCH_PRODUCT_USERS,
  FETCH_PRODUCT_CALCULATION,
  FETCH_PRODUCT_CALCULATION_SUCCESS,
  FETCH_PRODUCT_CALCULATION_FAILED,
  CREATE_MAT_VIEW,
  CREATE_MAT_VIEW_SUCCESS,
  CREATE_MAT_VIEW_FAILED,
  UPDATE_DL_PROCEDURE,
  UPDATE_DATA_SOURCE_PROCEDURE,
  UPDATE_DATA_SOURCE_PROCEDURE_SUCCESS,
  UPDATE_DATA_SOURCE_PROCEDURE_FAILED,
  UPDATE_DL_PROCEDURE_SUCCESS,
  UPDATE_DL_PROCEDURE_FAILED
} from "../types/realTimeData";

function* fetchKpiRealTimeData(action) {
  try {
    const state = yield select();
    const response = yield call(async () => {
      return await GetKpiRealTimeData(
        action.id,
        state.productTemplate.templateId,
        state.realTimeData.selectedUser?.email,
        state.realTimeData.selectedUser?.level || "individual",
        state.realTimeData.selectedUser?.assetId
      );
    });
    yield put({
      type: FETCH_REAL_TIME_KPI_DATA_SUCCESS,
      data: response.data
    });
  } catch (e) {
    yield put({ type: FETCH_REAL_TIME_KPI_DATA_FAILED, message: e });
  }
}

function* fetchProductUsers() {
  try {
    const state = yield select();
    const response = yield call(async () => {
      return await GetProductUsers(state.products.product?.id);
    });
    yield put({
      type: FETCH_PRODUCT_USERS_SUCCESS,
      users: response.data
    });
    yield put({
      type: FETCH_PRODUCT_CALCULATION
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCT_USERS_FAILED, message: e });
  }
}

function* fetchProductCalculations() {
  try {
    const state = yield select();
    const response = yield call(async () => {
      return await GetProductRealTimeData(
        state.productTemplate.templateId,
        state.realTimeData.selectedUser?.email,
        state.realTimeData.selectedUser?.level || "individual",
        state.realTimeData.selectedUser?.assetId
      );
    });
    yield put({
      type: FETCH_PRODUCT_CALCULATION_SUCCESS,
      data: response.data
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCT_CALCULATION_FAILED, message: e });
  }
}

function* createMatView(action) {
  try {
    const state = yield select();
    yield call(async () => {
      return await CreateMatView(
        state.productTemplate.templateId,
        action.forceCreate
      );
    });
    yield put({
      type: CREATE_MAT_VIEW_SUCCESS
    });
  } catch (e) {
    yield put({ type: CREATE_MAT_VIEW_FAILED, message: e });
  }
}

function* updateDlProcedure() {
  try {
    const state = yield select();
    yield call(async () => {
      return await UpdateDlProcedure(state.productTemplate.templateId);
    });
    yield put({
      type: UPDATE_DL_PROCEDURE_SUCCESS,
      forceCreate: true
    });
  } catch (e) {
    yield put({ type: UPDATE_DL_PROCEDURE_FAILED, message: e });
  }
}

function* updateDataSourceProcedure() {
  try {
    const state = yield select();
    yield call(async () => {
      return await UpdateDataSourceProcedure(state.productTemplate.templateId);
    });
    yield put({
      type: UPDATE_DATA_SOURCE_PROCEDURE_SUCCESS,
      forceCreate: true
    });
  } catch (e) {
    yield put({ type: UPDATE_DATA_SOURCE_PROCEDURE_FAILED, message: e });
  }
}

function* realTimeData() {
  yield takeLatest(FETCH_REAL_TIME_KPI_DATA, fetchKpiRealTimeData);
  yield takeLatest(FETCH_PRODUCT_USERS, fetchProductUsers);
  yield takeLatest(CREATE_MAT_VIEW, createMatView);
  yield takeLatest(UPDATE_DL_PROCEDURE, updateDlProcedure);
  yield takeLatest(UPDATE_DATA_SOURCE_PROCEDURE, updateDataSourceProcedure);
  yield takeLatest(UPDATE_DATA_SOURCE_PROCEDURE_SUCCESS, createMatView);
  yield takeLatest(ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED, updateDlProcedure);
  yield takeLatest(ADD_USER_SUCCEEDED, updateDlProcedure);
  yield takeLatest(
    DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
    updateDlProcedure
  );
}

export default realTimeData;
