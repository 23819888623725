import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS,
  CREATE_HISTORY,
  CREATE_HISTORY_SUCCEEDED,
  CREATE_HISTORY_FAILED,
  FETCH_PRODUCTS_INITIALS_SUCCESS,
  FETCH_PRODUCTS_INITIALS_FAILED,
  FETCH_PRODUCTS_INITIALS,
  UPDATE_PRODUCT_TEMPLATE,
  UPDATE_PRODUCT_TEMPLATE_SUCCEEDED,
  UPDATE_PRODUCT_TEMPLATE_FAILED
} from "../types/products";
import { put, call, takeLatest } from "redux-saga/effects";
import { CreateHistory, GetProducts } from "../../api/productApi";
import { OPEN_SNACKBAR } from "../types/snackbar";
import { GetProductInitials } from "../../api/sendingApi";
import { UpdateProductTemplate } from "../../api/productTemplate";

function* getProducts() {
  try {
    const products = yield call(async () => {
      return await GetProducts();
    });
    yield put({ type: FETCH_PRODUCTS_SUCCESS, products: products.data });
  } catch (e) {
    yield put({ type: FETCH_PRODUCTS_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error fetching products"
    });
  }
}

function* createHistory(action) {
  try {
    yield call(async () => {
      return await CreateHistory(action.templateId);
    });
    yield put({ type: CREATE_HISTORY_SUCCEEDED });
  } catch (e) {
    yield put({ type: CREATE_HISTORY_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error creating history"
    });
  }
}

function* getProductInitials(action) {
  try {
    const result = yield call(async () => {
      return await GetProductInitials(action.productId);
    });
    yield put({
      type: FETCH_PRODUCTS_INITIALS_SUCCESS,
      productsInitials: result.data.result
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCTS_INITIALS_FAILED, message: e });
  }
}

function* updateProductTemplate(action) {
  try {
    const result = yield call(async () => {
      return await UpdateProductTemplate(action.templateId, action.data);
    });
    yield put({
      type: UPDATE_PRODUCT_TEMPLATE_SUCCEEDED,
      productTemplate: result.data
    });
  } catch (e) {
    yield put({ type: UPDATE_PRODUCT_TEMPLATE_FAILED, message: e });
  }
}

function* product() {
  yield takeLatest(FETCH_PRODUCTS, getProducts);
  yield takeLatest(FETCH_PRODUCTS_INITIALS, getProductInitials);
  yield takeLatest(CREATE_HISTORY, createHistory);
  yield takeLatest(UPDATE_PRODUCT_TEMPLATE, updateProductTemplate);
}

export default product;
