import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  GetProductData,
  GetProductDates,
  GetSendlistData
} from "../../api/productApi";
import {
  FETCH_PRODUCT_DATA,
  FETCH_PRODUCT_DATA_FAILED,
  FETCH_PRODUCT_DATA_SUCCESS,
  FETCH_PRODUCT_DATES_FAILED,
  FETCH_PRODUCT_DATES_SUCCESS,
  FETCH_SENDLIST_DATA,
  FETCH_SENDLIST_DATA_FAILED,
  FETCH_SENDLIST_DATA_SUCCESS
} from "../types/productData";

function* fetchProductData(action) {
  try {
    const state = yield select();
    const productData = yield call(async () => {
      return await GetProductData(
        state.products.product?.id,
        action.selectedDatePeriod,
        state.productTemplate.templateId
      );
    });
    yield put({
      type: FETCH_PRODUCT_DATA_SUCCESS,
      productData: productData.data
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCT_DATA_FAILED, message: e });
  }
}

function* fetchProductDates() {
  try {
    const state = yield select();
    const productDates = yield call(async () => {
      return await GetProductDates(
        state.products.product?.id,
        state.productTemplate.templateId
      );
    });
    yield put({
      type: FETCH_PRODUCT_DATES_SUCCESS,
      productDates: productDates.data,
      selectedDatePeriod:
        productDates.data && productDates.data.length > 0
          ? productDates.data[0]
          : null
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCT_DATES_FAILED, message: e });
  }
}

function* fetchSendList(action) {
  try {
    const state = yield select();
    const productDates = yield call(async () => {
      return await GetSendlistData(
        state.products.product?.id,
        action.sendlistId
      );
    });
    yield put({
      type: FETCH_SENDLIST_DATA_SUCCESS,
      data: productDates.data
    });
  } catch (e) {
    yield put({ type: FETCH_SENDLIST_DATA_FAILED, message: e });
  }
}

function* productData() {
  yield takeLatest(FETCH_PRODUCT_DATA, fetchProductData);
  yield takeLatest(FETCH_SENDLIST_DATA, fetchSendList);
}

export default productData;
