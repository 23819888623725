import { put, call, takeLatest, delay, select } from "redux-saga/effects";
import {
  GetManagedUserTableFilter,
  GetManagedUserTables,
  GetColumnKeys,
  AddManagedUserTableFilter,
  GetManagedUserTableData,
  UpdateManagedUserTableFilter,
  DeleteManagedUserTableFilter
} from "../../api/managedUserApi";
import { jsonToBase64 } from "../../util/json";
import {
  ADD_MANAGED_USER_TABLE_FILTER,
  ADD_MANAGED_USER_TABLE_FILTER_FAILED,
  ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
  DELETE_MANAGED_USER_TABLE_FILTER,
  DELETE_MANAGED_USER_TABLE_FILTER_FAILED,
  DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
  FETCH_MANAGED_USERS_TABLES,
  FETCH_MANAGED_USERS_TABLES_FAILED,
  FETCH_MANAGED_USERS_TABLES_FILTER,
  FETCH_MANAGED_USERS_TABLES_FILTER_FAILED,
  FETCH_MANAGED_USERS_TABLES_FILTER_SUCCEEDED,
  FETCH_MANAGED_USERS_TABLES_SUCCEEDED,
  FETCH_MANAGED_USER_TABLE_COLUMN_KEYS,
  FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_FAILED,
  FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_SUCCESS,
  GET_MANAGED_USER_TABLE_DATA,
  GET_MANAGED_USER_TABLE_DATA_FAILED,
  GET_MANAGED_USER_TABLE_DATA_SUCCEEDED,
  UPDATE_MANAGED_USER_TABLE_FILTER,
  UPDATE_MANAGED_USER_TABLE_FILTER_FAILED,
  UPDATE_MANAGED_USER_TABLE_FILTER_SUCCEEDED
} from "../types/managedUser";
import { OPEN_SNACKBAR } from "../types/snackbar";

function* fetchManagedUserTables() {
  try {
    const managedUser = yield call(async () => {
      return await GetManagedUserTables();
    });
    yield put({
      type: FETCH_MANAGED_USERS_TABLES_SUCCEEDED,
      tables: managedUser.data
    });
  } catch (e) {
    yield put({ type: FETCH_MANAGED_USERS_TABLES_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error getting managed user tables"
    });
  }
}

function* fetchManagedUserFilter(action) {
  try {
    const filter = yield call(async () => {
      return await GetManagedUserTableFilter(action.templateId);
    });
    yield put({
      type: FETCH_MANAGED_USERS_TABLES_FILTER_SUCCEEDED,
      filtersList: filter.data.filters,
      managedUserTable: filter.data.managedUserTable
    });
  } catch (e) {
    yield put({ type: FETCH_MANAGED_USERS_TABLES_FILTER_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error getting managed user filters"
    });
  }
}

function* fetchColumnKeys(action) {
  try {
    yield delay(200);
    const state = yield select();
    const keys = yield call(async () => {
      return await GetColumnKeys(
        action.tableId,
        action.column,
        action.filter,
        action.templateId
      );
    });
    const values = action.filter?.split(" ");
    const disabled = state.managedUsers.disabled ?? {};
    if (values && values.length > 1) {
      for (const value of values) {
        if (
          !keys.data.result.find(
            r => value.trim().toLowerCase() == r.id.toLowerCase()
          )
        ) {
          const newValues = disabled[action.column] ?? [];
          newValues.push(value.trim());
          disabled[action.column] = newValues;
        }
      }
    }
    yield put({
      type: FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_SUCCESS,
      keys: keys.data.result,
      column: action.column,
      totalCount: keys.data.totalCount,
      disabled
    });
  } catch (e) {
    yield put({
      type: FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_FAILED,
      message: e
    });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error getting column keys"
    });
  }
}

function* addManagedUserFilter(action) {
  try {
    yield delay(200);
    const filter = yield call(async () => {
      return await AddManagedUserTableFilter(action.data);
    });
    if (action.data?.managedUserTableId && action.data?.productTemplateId) {
      yield put({
        type: GET_MANAGED_USER_TABLE_DATA,
        tableId: action.data.managedUserTableId,
        filters: null,
        pageSize: 10,
        skip: 0,
        templateId: action.data.productTemplateId
      });
    }
    const state = yield select();
    let filtersList = state.managedUsers.filtersList;
    if (action.data.id) {
      const index = filtersList.findIndex(f => f.id === action.data.id);
      if (index > -1) {
        filtersList[index] = filter.data;
      }
    } else {
      filtersList = [...filtersList, filter.data];
    }
    yield put({
      type: ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
      filtersList
    });
  } catch (e) {
    yield put({
      type: ADD_MANAGED_USER_TABLE_FILTER_FAILED,
      message: e
    });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error adding filter"
    });
  }
}

function* updateManagedUserFilter(action) {
  try {
    const filter = yield call(async () => {
      return await UpdateManagedUserTableFilter(action.data);
    });
    if (action.data?.managedUserTableId && action.data?.productTemplateId) {
      yield put({
        type: GET_MANAGED_USER_TABLE_DATA,
        tableId: action.data.managedUserTableId,
        filters: null,
        pageSize: 10,
        skip: 0,
        templateId: action.data.productTemplateId
      });
    }
    yield put({
      type: UPDATE_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
      filtersList: filter.data.filters
    });
  } catch (e) {
    yield put({
      type: UPDATE_MANAGED_USER_TABLE_FILTER_FAILED,
      message: e
    });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error adding managed DL"
    });
  }
}

function* deleteManagedUserFilter(action) {
  try {
    yield call(async () => {
      return await DeleteManagedUserTableFilter(action.id);
    });
    const state = yield select();
    let filtersList = state.managedUsers.filtersList;
    const index = filtersList.findIndex(f => f.id === action.id);
    if (index > -1) {
      filtersList.splice(index, 1);
    }
    yield put({
      type: DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
      filtersList
    });
  } catch (e) {
    yield put({
      type: DELETE_MANAGED_USER_TABLE_FILTER_FAILED,
      message: e
    });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error deleting managed DL filter"
    });
  }
}

function* getManagedUserTableData(action) {
  try {
    yield delay(200);
    const state = yield select();
    const response = yield call(async () => {
      return await GetManagedUserTableData(
        action.tableId,
        jsonToBase64(state.managedUsers.searchFilters),
        action.pageSize,
        action.skip,
        action.templateId
      );
    });
    yield put({
      type: GET_MANAGED_USER_TABLE_DATA_SUCCEEDED,
      data: response.data.tableData,
      dataTotalCount: response.data.totalCount
    });
  } catch (e) {
    yield put({
      type: GET_MANAGED_USER_TABLE_DATA_FAILED,
      message: e
    });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error getting data"
    });
  }
}

function* managedUser() {
  yield takeLatest(FETCH_MANAGED_USERS_TABLES, fetchManagedUserTables);
  yield takeLatest(FETCH_MANAGED_USERS_TABLES_FILTER, fetchManagedUserFilter);
  yield takeLatest(FETCH_MANAGED_USER_TABLE_COLUMN_KEYS, fetchColumnKeys);
  yield takeLatest(ADD_MANAGED_USER_TABLE_FILTER, addManagedUserFilter);
  yield takeLatest(UPDATE_MANAGED_USER_TABLE_FILTER, updateManagedUserFilter);
  yield takeLatest(GET_MANAGED_USER_TABLE_DATA, getManagedUserTableData);
  yield takeLatest(DELETE_MANAGED_USER_TABLE_FILTER, deleteManagedUserFilter);
}

export default managedUser;
