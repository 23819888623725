import {
  put,
  call,
  select,
  takeLatest,
  cancelled,
  fork,
  take,
  cancel,
  debounce
} from "redux-saga/effects";
import { SaveProductTemplate } from "../../api/snippetApi";
import {
  GetProductTemplateDl,
  UpdateProductTemplateDl
} from "../../api/productTemplate";
import { GetDlColumns } from "../../api/ninjaDlApi";
import axios from "../../api/axios";
import { compare } from "../../util/array";
import { validateEmail } from "../../util/validation";
import {
  FETCH_PRODUCT_DL,
  FETCH_PRODUCT_DL_SUCCESS,
  FETCH_PRODUCT_DL_FAILED,
  CANCEL_FETCH_PRODUCT_DL,
  PUBLISH_TEMPLATE,
  PUBLISH_TEMPLATE_FAILED,
  PUBLISH_TEMPLATE_SUCCESS,
  UPDATE_PRODUCT_DL_FAILED,
  UPDATE_PRODUCT_DL_SUCCESS,
  UPDATE_PRODUCT_DL,
  FETCH_DATA_SOURCE_COLUMNS_SUCCESS,
  FETCH_DATA_SOURCE_COLUMNS_FAILED,
  FETCH_DATA_SOURCE_COLUMNS
} from "../types/productTemplate";
import { SET_NINJA_DL_MENTIONS } from "../types/autoComplete";
import { OPEN_SNACKBAR } from "../types/snackbar";
import { GetDataSourceColumns } from "../../api/dataSourceApi";
import { updateDlProcedure } from "../actions/realTimeData";

const validateSave = (
  templateSnippets,
  product,
  dl,
  columnOptions,
  activeChannel,
  managedUsers,
  productTemplate
) => {
  if (!templateSnippets || templateSnippets.length < 1) {
    return {
      value: false,
      message: "You need to have snippet in your product"
    };
  }
  if (product.isSql == false) {
    return {
      value: true
    };
  }
  for (let i = 0; i < templateSnippets.length; i++) {
    const snippet = templateSnippets[i];
    if (
      [
        "kpi",
        "heatmap",
        "staticComment",
        "comment",
        "dataTable",
        "overall"
      ].includes(snippet.snippetTypeName) &&
      !snippet.templateSnippet?.dataBlending
    ) {
      return {
        value: false,
        message: `You need to map data source for ${
          snippet.snippetTypeName
        } number ${i + 1}`
      };
    }
  }
  if (!product.reportType) {
    return {
      value: false,
      message: "You need to add report type for your product"
    };
  }
  if (!productTemplate.productDl || !productTemplate.productDl.dl) {
    return {
      value: false,
      message: "You need to connect users"
    };
  }
  for (const c of columnOptions.data) {
    if (c.value == "from") {
      if (!c.mapValue && activeChannel.channel === 1) {
        return {
          value: false,
          message: "You need to add from email"
        };
      }
      if (!validateEmail(c.mapValue) && activeChannel.channel === 1) {
        return {
          value: false,
          message: "from need to be email"
        };
      }
    }
    if (c.value == "from_name") {
      if (!c.mapValue && activeChannel.channel === 1) {
        return {
          value: false,
          message: "You need to add from name"
        };
      }
    }
    if (c.value == "from_sms") {
      if (!c.mapValue && activeChannel.channel === 2) {
        return {
          value: false,
          message: "You need to add from sms"
        };
      }
    }
    if (c.value == "subject") {
      if (!c.mapValue && activeChannel.channel === 1) {
        return {
          value: false,
          message: "You need to add subject"
        };
      }
    }
  }
  return {
    value: true
  };
};

function* publishTemplate(action) {
  try {
    const state = yield select();
    const validation = yield call(() =>
      validateSave(
        state.templateSnippet.templateSnippets,
        state.products.product,
        state.dl,
        state.columnOptions,
        state.activeChannel,
        state.managedUsers,
        state.productTemplate
      )
    );
    if (!validation.value) {
      yield put({ type: PUBLISH_TEMPLATE_FAILED });
      yield put({
        type: OPEN_SNACKBAR,
        snackbarType: "info",
        message: validation.message
      });
      return;
    }
    const tempTemplateSnippets = state.templateSnippet.templateSnippets.map(
      (t, index) => {
        return {
          ...t,
          order: index
        };
      }
    );
    const selectedProduct = state.products.product;
    const snippets = tempTemplateSnippets.sort((a, b) =>
      compare(a, b, "order", true)
    );
    yield call(async () => {
      return await SaveProductTemplate(
        snippets,
        {
          ...selectedProduct
        },
        state.columnOptions.data,
        action.templateId,
        state.activeChannel.channel
      );
    });
    yield put({
      type: PUBLISH_TEMPLATE_SUCCESS
    });
  } catch (e) {
    yield put({ type: PUBLISH_TEMPLATE_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error publishing template"
    });
  }
}

async function getDlColumns(dl) {
  var columns = [];
  if (dl?.id && dl.type == 0) {
    if (dl.customFieldsColumns) {
      columns = [...dl.customFieldsColumns];
    }
  } else if (dl?.id) {
    const columnResponse = await GetDlColumns(dl.id);
    if (columnResponse?.data) {
      columns = [
        ...columnResponse.data?.map(d => {
          return {
            ...d,
            display: d.name?.toUpperCase()
          };
        })
      ];
    }
  }
  return columns;
}

function* fetchProductDlTask(action) {
  let ajaxRequest = axios.CancelToken.source();
  try {
    const response = yield call(async () => {
      return await GetProductTemplateDl(action.templateId, ajaxRequest.token);
    });
    var columns = [];
    if (response?.data?.dl?.id) {
      columns = yield call(async () => {
        return await getDlColumns(response.data.dl);
      });
    }
    if (columns)
      yield put({
        type: SET_NINJA_DL_MENTIONS,
        columns
      });
    yield put({
      type: FETCH_PRODUCT_DL_SUCCESS,
      productDl: response.data,
      productDlColumns: columns
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCT_DL_FAILED });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error fetching DL"
    });
  } finally {
    if (yield cancelled()) {
      ajaxRequest.cancel();
    }
  }
}
function* fetchProductDl(action) {
  const task = yield fork(fetchProductDlTask, action);
  yield take(CANCEL_FETCH_PRODUCT_DL);
  yield cancel(task);
}

function* updateProductDl(action) {
  try {
    const response = yield call(async () => {
      return await UpdateProductTemplateDl(action.productDl);
    });
    var columns = [];
    if (response?.data?.dl?.id) {
      columns = yield call(async () => {
        return await getDlColumns(response.data.dl);
      });
    }
    if (columns)
      yield put({
        type: SET_NINJA_DL_MENTIONS,
        columns
      });
    yield put({
      type: UPDATE_PRODUCT_DL_SUCCESS,
      productDl: response.data,
      productDlColumns: columns
    });
    yield put(updateDlProcedure());
  } catch (e) {
    yield put({ type: UPDATE_PRODUCT_DL_FAILED });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error updating product DL"
    });
  }
}

function* fetchDataSourceColumns(action) {
  try {
    const columns = yield call(async () => {
      return await GetDataSourceColumns(action.templateId);
    });
    yield put({
      type: FETCH_DATA_SOURCE_COLUMNS_SUCCESS,
      ninjaDataSourceColumns: columns.data
    });
  } catch (e) {
    yield put({ type: FETCH_DATA_SOURCE_COLUMNS_FAILED, message: e });
  }
}

function* productTemplate() {
  yield takeLatest(PUBLISH_TEMPLATE, publishTemplate);
  yield takeLatest(FETCH_PRODUCT_DL, fetchProductDl);
  yield debounce(700, UPDATE_PRODUCT_DL, updateProductDl);
  yield takeLatest(FETCH_DATA_SOURCE_COLUMNS, fetchDataSourceColumns);
}

export default productTemplate;
